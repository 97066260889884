@mixin mobile-375 {
  @media only screen and (min-width: 375px) and (max-width: 812px) {
    @content;
  }
}

@mixin mobile-360 {
  @media screen and (max-width: 360px) {
    @content;
  }
}

@mixin mobile-320 {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin allMobile {
  @media only screen and (min-width: 320px) and (max-width: 812px) {
    @content;
  }
}
