$primary-color: #212121;

button.pill {
  &:disabled {
    background-color: #e9ecef !important;
    color: #000000 !important;
  }
  cursor: pointer;
  border: 1px solid #556080;
  outline: none;
  font-weight: 400;
  border-radius: 100px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #556080;
  padding: 16px 12px;
  margin: 0px 4px;
  color: #556080;
  display: flex;
  height: 48px;
  align-items: center;
  &-selected {
    background-color: $primary-color;
    color: white;
  }
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

button.nav-pill {
  background: #fafafa;
  border-radius: 24px;
  color: #0F0F0F;
  font-size: 14px;
  &-selected{
    background: #F2FAFF;
    color: #3FA0D9;
  }
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
