@use "responsive";

@mixin card-size($padding-y, $padding-x) {
  padding: $padding-y $padding-x;
}

div.kycCard{
  border-radius: 5px;
  background: #fff;
  width: 500px;
  @include responsive.allMobile {
    width: 100%;
    background: #fff;
  }
}

div.card {
  &-sm {
    @include card-size(0.55rem, 1.5rem);
  }
  &-xs {
    @include card-size(0.4rem, 1rem);
  }
  &-md {
    @include card-size(1.25rem, 1.563rem);
  }
  background: #fff;
  &-bg-grey {
    background: #f4f4f4;
  }
  &-bg-accent {
    background: #F5F8FA;
  }
  &-bg-inherit {
    background: inherit;
  }
  &-bg-dark{
    background: #181A20;

  }
  &-shadow {
    box-shadow: 0px 20px 40px #d9dde0;
  }
  &-border {
    border: 1px solid #e5e5e5;
  }
  &-r-lg {
    border-radius: 15px;
  }
  &-r-md {
    border-radius: 10px;
  }
  &-r-sm {
    border-radius: 5px;
  }
  hr{
    border: none;
    border-bottom: 0.5px solid #C6C6C6;
  }
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)
}
