input.custom-input{
  background: #F4F4F4;
  border-radius: 4px;
  padding: 12px 16px;
  height: 48px;
  font-size: 14px;
  color: #000;
  ::placeholder{
    color: #B7B9BE;
    font-size: 14px;
  }
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}
