@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "/src/themes/base.scss";
@import "@fontsource/fira-sans";
@import "@fontsource/fira-sans/300.css";
@import "@fontsource/fira-sans/500.css";
@import "@fontsource/fira-sans/600.css";
@import "@fontsource/fira-sans/700.css";
@import "@fontsource/fira-sans/900.css";

$font-family: "Fira Sans" !important;

* {
  font-family: $font-family;
}

body {
  background: #fafcfc;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.mat-tab-header {
  border: none !important;
  display: none !important;
}

.mat-tab-label {
  padding-right: 4px !important;
  min-width: none !important;
}

.choice-div {
  margin: 8px 0px;
  &.selected {
    background: rgba(191, 226, 247, 0.1);
    border: 1px solid #bfe2f7;
    box-sizing: border-box;
    border-radius: 4px;
    transition: background 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    .choice-number {
      background-color: #2da5ec;
      color: white;
      transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }

  .choice-number {
    font-size: 16px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    color: #000;
    border: 0.3px solid #2da5ec;
  }
}

.pointer {
  cursor: pointer;
}

.otpContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mat-dialog-container {
  padding: 16px !important;
  border-radius: 10px !important;
}
