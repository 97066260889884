$primary-color: #3fa0d9;
$btn-block-spacing-y: 0.5rem !default;

@mixin button-size($padding-y, $padding-x) {
  padding: $padding-y $padding-x;
}

button.cbtn {
  &:disabled {
    background-color: #e9ecef !important;
    color: #000000 !important;
    border: none;
  }
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 400;
  border-radius: 5px;
  &-primary {
    background-color: $primary-color;
    color: white;
    &:hover {
      color: white;
      opacity: 0.76;
      background-color: $primary-color;
    }
  }
  &-danger {
    color: #f36759;
  }
  &-secondary {
    background: #eaeaea;
    color: #000;
    &:hover {
      color: #000;
      opacity: 0.76;
      background-color: #e9ecef;
    }
  }
  &-outline {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: #fff;
    font-weight: 400;
    &:hover {
      color: white;
      background-color: $primary-color;
    }
    &:focus {
      outline: none;
    }
  }
  &-inline {
    color: white;
    background-color: $primary-color;
    border: 1px solid #fff;
    &:hover {
      border: 1px solid $primary-color;
      color: $primary-color;
      background-color: #fff;
    }
  }
  &-plain {
    color: $primary-color;
    background-color: inherit;
    border: none;
    &:hover {
      border: none;
      color: $primary-color;
      background-color: inherit;
    }
    &:disabled {
      background-color: inherit;
      color: #6c7884 !important;
      border: none;
    }
  }
  &-alt {
    background: #ebfff9;
    color: #2c76a0;
  }
  &-lg {
    @include button-size(0.88rem, 3.7rem);
  }
  &-md {
    @include button-size(0.75rem, 2rem);
  }
  &-sm {
    @include button-size(0.55rem, 1.5rem);
  }
  &-xs {
    @include button-size(0.4rem, 1rem);
  }
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
