$primary-color: #3fa0d9;
.fs-48 {
  font-size: 48px;
}
.fs-40{
  font-size: 40px;
}
.fs-32{
  font-size: 32px;
}
.fs-24 {
  font-size: 24px;
}
.fs-20 {
  font-size: 20px;
}
.fs-16{
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.fs-10 {
  font-size: 10px;
}
.fw-full {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700{
  font-weight: 700 !important;
}
.dark-text{
  color: #0D1B2A !important;
}
.semi-dark-text{
  color: #4E5D6D !important;
}
.light-grey-text{
  color: #B7B9BE;
}
.grey-02{
  color: #7E8593;
}
.grey-03{
  color: #EBEBEB;
}
.grey-04{
  color: #6C7884;
}
.text-white{
  color: #fff;
}
.alt-white{
  color: #FAFAFA;
}
.text-green{
  color: #34E593;
}
.text-warning{
  color: #FE7E4D;
}
.text-danger{
  color: #E74C3C;
}
.primary-color{
  color: $primary-color !important;
}
.u-case{
  text-transform: uppercase !important;
}
.d-case{
  text-transform: lowercase !important;
}
.c-case{
  text-transform: capitalize !important;
}
.t-left{
  text-align: left !important;
}
.t-right{
  text-align: right !important;
}
.t-center{
  text-align: center !important;
}
.t-justify{
  text-align: justify !important;
}
