.badge{
  &-primary{
    background: #D6F0FF;
    color: #2DA5EC;
  }
  &-success{
    background: #F5FFFC;
    color: #00B77E;
  }
  &-danger{
    background: #FDF2F1;
    color: #6C7884;
  }
  &-r-lg{
    border-radius: 100px;
  }
  &-r-md{
    border-radius: 5px;
  }
}
